import React from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { useColorMode } from '../context/ColorModeContext';  // Use the custom hook to access the context
import { useTheme } from '@mui/material/styles';
import { DarkMode, LightMode } from '@mui/icons-material';


function ButtonIcon() {
  const theme = useTheme();
  const { toggleColorMode } = useColorMode();  

  return (
    <Box>
        <IconButton sx={{ ml: 1, color: 'white' }} onClick={toggleColorMode}>
            <DarkMode />
        </IconButton>
    </Box>
  );
}

export default ButtonIcon;

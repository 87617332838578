import React, { useEffect, useRef, useState } from 'react';
import Nav from './components/Nav';
import Homepage from './pages/Homepage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ColorMode from './components/ColorModeButton';
import { ColorModeProvider } from './context/ColorModeContext';
import Divider from '@mui/material/Divider';
import Card from './components/Card';
import Footer from './components/Footer';
import { ShowButtonProvider, useShowButton } from './context/ShowButtonContext';


function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );


  return (

    <div style={{height:'100vh'}}>
      <Divider className='gradient-background' sx={{
        height: 10, }} />
      <ColorModeProvider>
      <CssBaseline />
      
      <Nav />
      <ShowButtonProvider>
      <Router>
          <Routes>
            <Route path="/" element={<Homepage />} />
          </Routes>
      </Router>
      <Footer /> 
      </ShowButtonProvider>
      </ColorModeProvider>
    </div>

  );
    
}

export default App;

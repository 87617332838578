import * as React from 'react';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Button from '@mui/material/Button';

export default function SimpleGrow({ description, onClick }) { // Add onClick in the props
  return (
    <Box sx={{ height: 180, width: '100%' }}>
      <Box sx={{
          display: 'flex',
          justifyContent: 'center', // Center horizontally
          alignItems: 'center', // Center vertically
          height: '25%', // Updated to take full height of parent
        }}
      >
        <Grow
          in={true}
          style={{ transformOrigin: '0 0 0' }}
          {...(true ? { timeout: 1700 } : {})}
        >
          <Button 
            variant="contained" 
            style={{textTransform: 'none', textDecoration: 'none', fontSize: '1.3rem'}} 
            onClick={onClick} // Pass onClick to the Button
          >
            <b>{description}</b>
          </Button>
        </Grow>
      </Box>
    </Box>
  );
}

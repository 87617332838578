import React from 'react';
import { Card, CardMedia, CardContent, Typography, Grid, Box } from '@mui/material';

const AboutMeCard = ({imageUrl, description, title}) => {
    return (
        <Card sx={{ display: 'flex', maxWidth: 960, margin: 'auto', height:'100%', boxShadow: 5}}>
            <CardMedia
                component="img"
                sx={{ width: 300, height: 400 }}  // Adjust size as needed
                image={imageUrl}  // Path to your image
                alt="Jim"
            />
            <Box sx={{ flexGrow: 1, // Allows content to expand
                    display: 'flex',
                    flexDirection: 'column',}}>
                <CardContent sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, }}>
                    <Typography variant="h3" className='text-center'>
                        <b>{title}</b>
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="p" >
                        {description}
                    </Typography>
                </CardContent>
            </Box>
        </Card>
    );
};

export default AboutMeCard;

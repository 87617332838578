import React, { useState, useEffect } from 'react';
import ColorMode from './ColorModeButton';

function Nav() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {/* Left side: Jimmy Lin brand */}
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}>
                    <a className="navbar-brand" href="/">Jimmy Lin</a>
                </div>
                
                {/* Center: Navigation items */}
                <div style={{ flex: 2, display: 'flex', justifyContent: 'center' }}>
                    {isMobile ? (
                        <div>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNavDropdown">
                                <ul className="navbar-nav">
                                    <li className="nav-item"><a className="nav-link" aria-current="page" href="/">Home</a></li>
                                    {/* <li className="nav-item"><a className="nav-link" href="/projects">Projects</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#">Experiences</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#">Live Bot Tracker</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#">About Me</a></li> */}
                                </ul>
                            </div>
                        </div>
                    ) : (
                        <ul className="navbar-nav">
                            <li className="nav-item"><a className="nav-link" href="/">Home</a></li>
                            {/* <li className="nav-item"><a className="nav-link" href="/projects">Projects</a></li>
                            <li className="nav-item"><a className="nav-link" href="#">Experiences</a></li>
                            <li className="nav-item"><a className="nav-link" href="#">Live Bot Tracker</a></li>
                            <li className="nav-item"><a className="nav-link" href="#">About Me</a></li> */}
                        </ul>
                    )}
                </div>

                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', paddingRight: '15px' }}>
                    <ColorMode />
                </div>
            </div>
        </nav>
    );
}

export default Nav;

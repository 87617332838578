import React, { createContext, useState, useContext } from 'react';

const ShowButtonContext = createContext();

export const useShowButton = () => useContext(ShowButtonContext);

// Create the provider component
export const ShowButtonProvider = ({ children }) => {
    const [showButton, setShowButton] = useState(false);

    return (
        <ShowButtonContext.Provider value={{ showButton, setShowButton }}>
            {children}
        </ShowButtonContext.Provider>
    );
};

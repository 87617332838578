import React, { useState } from 'react';
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Button, Typography, Modal, Box, Chip } from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';

function ProjectCard({ title, description = '', imageUrl, skills }) {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        maxWdith: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 540,
        p: 4,
    };

    // Safe slicing
    const shortDescription = description.length > 150 ? description.slice(0, 180) + '...' : description;

    return (
        <div>
            <Card sx={{maxWidth: 345, margin: 'auto', height:'100%', boxShadow: 5}}>
                <CardContent>
                  <Typography sx={{fontWeight:'bold'}}variant="h3" component="div" align='center'>
                            {title}
                  </Typography>
                </CardContent>
                  <CardMedia
                      component="img"
                      height="250"
                      image={imageUrl}
                      alt={title}
                  />
                  <CardContent>
                      <Typography variant="body2" color="text.secondary" align='center'>
                          {shortDescription}
                      </Typography>
                      <div align="center" style={{ justifyContent: 'center' }}>
                        {skills.map((skill, index) => (
                            <Chip
                                key={index}
                                label={skill}
                                color="primary"
                                sx={{ fontWeight: 'bold' }}
                            />
                        ))}
                      </div>
                  </CardContent>
              <CardActions sx={{justifyContent: 'center'}}>
                  <Button size="medium" color="primary" onClick={handleOpen}>
                      Show More
                  </Button>
              </CardActions>
            </Card>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography sx={{fontWeight:'bold'}} id="modal-modal-title" variant="h3" component="h2" align='center'>
                        {title}
                    </Typography>
                    <CardMedia
                        component="img"
                        height="250"
                        image={imageUrl}
                        alt={title}
                    />
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {description}
                    </Typography>
                    <div align="center" style={{ justifyContent: 'center' }}>
                        {skills.map((skill, index) => (
                            <Chip
                                key={index}
                                label={skill}
                                color="primary"
                                sx={{ fontWeight: 'bold' }}
                            />
                        ))}
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default ProjectCard;

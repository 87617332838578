import React, { useEffect, useRef, useState } from 'react';
import '../css/background.css';
import { useTheme } from '@mui/material/styles';
import { useShowButton } from '../context/ShowButtonContext';
import LinkedInIcon from '@mui/icons-material/LinkedIn';


function Footer() {

    const theme = useTheme();

    const { showButton, setShowButton } = useShowButton();


    return (
        <>
        {showButton && (
            <div>
            <div className='gradient-background' style={{height: '7rem', opacity: 1, display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',}}> 
                <a href="https://www.linkedin.com/in/lin-jim/" style={{ color: 'inherit'}}>   
                    <h5><b><LinkedInIcon style={{ fontSize: '50px', color: '#0A66C2' }} /></b></h5>
                </a>
                <a href="mailto:jimlin@umich.edu" style={{ color: theme.palette.text.primary,  }}>
                    <h5><b><span>Jimlin@umich.edu</span></b></h5>
                </a>
            </div>
        </div>)
        }
        </>
       
    );

}

export default Footer;
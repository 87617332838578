import React, { useEffect, useRef, useState } from 'react';
import { useShowButton } from '../context/ShowButtonContext';
import { Typed } from 'react-typed';
import Button1 from '../components/ButtonGrow';
import '../css/background.css';
import Divider from '@mui/material/Divider';
import ProjectCard from '../components/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import myPhotoZoomed from '../Assets/Ross-Headshot-ZoomedIn.jpg';
import stonks from '../Assets/StonksMemePic.jpg';
import C1_logo from '../Assets/C1_Logo.png';
import start from '../Assets/Start.png';
import AboutMeCard from '../components/AboutMeCard';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { Grid } from '@mui/material';


function Homepage() {
    const contentRef = useRef(null);
    const spacerRef = useRef(null);
    const content = useRef(null);
    const content2 = useRef(null);
    const { showButton, setShowButton } = useShowButton();

    const umichStyle = 'color: #FFCB05; background-color: #00274C; padding: 2px 5px;';

    
    React.useEffect(() => {

        // Initialize Typed.js for animated typing effect
        const typed = new Typed(content.current, {
          strings: ["<span class='gradient-text' style='opacity:1; font-size: 5rem;'>Hi! I'm Jimmy</span>"],
          typeSpeed: 50,
          onComplete: (self) => {
              self.cursor.remove();
              new Typed(content2.current, {
                strings: [
                  'CS &amp; Math @ the <b><span style="' + umichStyle + '">University of Michigan</span></b>'
              ],
              typeSpeed: 30,
              onComplete: () => {
                setShowButton(true);
              }
            });
            
          }
        });
    
        return () => {
          typed.destroy();
        };
    }, []); // Empty dependency array ensures this runs only once after initial render


    return (
        <div>
            <div className="container" >
                <div className="text-center mt-5" ref={spacerRef}>
                <div className="text-center spacer"></div>
                </div>
                <div className="text-center mt-5" ref={contentRef}>
                    <h1><b><span ref={content}></span></b></h1>
                    <p className="lead"><span style = {{ fontSize: '1.5rem' }} ref={content2}></span></p>
                    {showButton && 
                        <Button1 onClick={() => {
                          const scrollTarget = document.getElementById('scroll');
                          if (scrollTarget) {
                              scrollTarget.scrollIntoView({ behavior: 'smooth' });
                          }
                        }} description={'Learn more!'}/>
                    }
                </div>
            </div>
            {showButton && (
              <>
                <div className="text-center spacer2">
                      <KeyboardDoubleArrowDownIcon className="arrow"/>
                </div>
                <Divider className='gradient-background' sx={{ height: 3, borderBottomWidth: 7}} />
                <div className="text-center spacer2" id="scroll"></div>
                <AboutMeCard imageUrl={myPhotoZoomed} title="Introduction" description="Thanks for visting my website! I built this using React along with Material UI and it is hosted on an AWS S3 instance.
                  If you want to learn more about me, my work experiences, or more of my projects, scroll down">
                 </AboutMeCard>
                  
                <div className="text-center spacer2"></div>

                  <Grid container spacing={2} justifyContent="center"> {/* Centers the cards and adds spacing between them */}
                    <Grid item xs={12} sm={6} md={4}> {/* Adjusts for responsive behavior */}
                      <ProjectCard 
                        title="Trading Bots" 
                        description="I coded a Python trading bot which is deployed on Interactive Brokers and uses their API to draw market data.
                        Engineered using mean-reversion strategies and statistical arbitrage concepts which include testing for collinearity and utilizing Augmented Dickey-Fuller along with Walk-Forward optimizations.
                        My best bot achieves a Sharpe ratio of 1.18 trading ES futures
                        " 
                        imageUrl={stonks} 
                        skills={["Python", "C#"]} 
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}> {/* Duplicate the item for demonstration */}
                      <ProjectCard 
                        title="Global Datastore" 
                        description="I integrated an AWS Elasticache Global Datastore with an existing TSYS interface and used AWS SQS Queues in addition to AWS Lambdas to complete the architecture and allow communication between AWS regions. 
                        This had the effect of saving Capital One millions of dollars each year in unnecessary external api calls to TSYS (our customer data provider)
                        I also modified the TSYS interface using Java Spring Boot and used Python to perform I/O operations on the redis cache which
                        averages 400 transactions per second and has an automated failover system in case of AWS outages
                        Architecture includes an automated failover system which uses an SQS to ping the opposite region every minute, if an outage is detected a lambda will automatically promote the secondary cache to the primary
                        " 
                        imageUrl={C1_logo} 
                        skills={["Java Spring Boot", "AWS Elasticache", "GoLang", "Python", "AWS SQS", "AWS Lambda"]} 
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}> {/* Duplicate the item for demonstration */}
                      <ProjectCard 
                        title="Bubble" 
                        description="I built an end to end encrypted alternative to Life360 with fellow University of Michigan students. Using Rust I created a backend email delivery system that is integrated with the SendGrid API.
                        In addition, I also implemented and optimized an IP-dependent rate limiting system utilizing Rust’s performance-oriented features
                        "
                        imageUrl={start} 
                        skills={["Rust", "SendGrid"]} 
                      />
                    </Grid>
                  </Grid>`

                <div className="text-center spacer2"></div>

              </>
            )}

        </div>
        
    );
}

export default Homepage;